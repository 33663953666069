<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <!--full width banner-->
    <div class="row justify-content-center">
        <div>
            <img src="../assets/main_bg.png" :style="isMobile()? 'margin-top:60px; width:100%':'width:100%;'">
        </div>

        <!-- what we do -->
        <div class="divide60" id="Company"></div>
        <div class="col-sm-10 col-md-9 col-lg-9 col-xl-6">
            <div class="center-heading">
                <h2>What we do</h2>
                <span class="center-line"></span>
                <p class="sub-text margin40 p-3">
                    (주)루다크루는 고객의 핵심 가치를 이해하고 기획하며 최신의 기술을 이용하여 SYSTEM(시스템), WEB(웹), Mobile App(iOS,Android)을 개발합니다.<br>
                    Cloud(클라우드) 시스템을 활용한 쇼핑몰 서비스, IoT 관제 서비스 등 다양한 개발 경험을 토대로 안정적인 개발과 운영을 합니다.
                </p>
            </div>
        </div>

        <!-- 카드 -->
        <div class="divide30"></div>
        <div class="col-sm-12 col-md-10 col-lg-10 col-xl-6">
            <div class="row justify-content-center">
                <b-card-group columns v-for="(item,index) in card" :key="index" style="max-width:20rem; margin-right:2px margin-left:2px" class="col-sm-12 col-md-9 col-lg-9 p-0">
                    <b-card
                        style="max-width: 25rem; min-height:18rem"
                        class="m-1"
                    >
                    <img :src="item.png" class="margin10" style="width:16px hegiht:16px"/>
                    <b-card-title>{{item.title}}</b-card-title>
                    <span class="center-line"></span>
                        <b-card-text class="pt-1" v-for="(itemContent,index) in item.content" :key="index">
                             {{itemContent}}
                       </b-card-text>
                    </b-card>
                </b-card-group>
            </div>
        </div>

        <!-- History -->
        <!-- <div class="divide60" id="History"></div>
        <div class="col-sm-12 col-md-10 col-lg-8">
            <div class="center-heading">
                <h2>History</h2>
                <span class="center-line"></span>
            </div>  
            <div class="row p-3" v-for="(item,index) in history" :key="index">
                <div class="col-md-2">
                    <h4 class="tithistory"><font color="ef402a">{{item.year}}</font></h4>
                </div>
                <div class="col-md-10" >
                    <ul class="list-unstyled cat-list">
                        <li v-for="(subItem, index) in item.subItem" :key="index">
                            <span class="si-text">{{subItem.month}}</span>{{subItem.content}}</li>
                    </ul>
]                </div>
            </div>
        </div> -->

        <!-- RecentWorks -->
        <div class="divide60" id="RecentWorks"></div>
        <div class="col-sm-8">
            <div class="center-heading">
                <h2>Recent Works</h2>
                <span class="center-line"></span>
            </div>
            <div class="row justify-content-center">
                <div :class="isMobile()? 'col-11':'col-11'">
                    <!-- <div class="project-post"> -->
                        <div class="item-img-wrap">
                            <h4>침입/화재 탐지 IOT 관제시스템</h4>
                            <img src='../assets/apps/iot.png' class="img_1" alt="working">
                           <!-- <img src='../assets/apps/iot.png' class="img-responsive" alt="working"> -->
                        </div>
                    <!-- </div> -->
                </div>
                <div v-for="(item,index) in portfolio_iot" :key="index"  :class="isMobile()? 'col-11':'col-4'" style="margin-bottom: 50px;">
                    <!-- <div class="project-post"> -->
                        <!-- <div class="work-desc"> -->
                            <h4>{{item.customer}}</h4>
                            <!-- <h4><a href="javascript:void(0);">{{item.customer}}</a></h4> -->
                            <span>{{item.subject}}</span>
                            <div class="item-img-wrap">
                                <img :src="item.png" class="img-responsive"  alt="working">
                            </div>
                        <!-- </div> -->
                    <!-- </div> -->
                </div>

                <div :class="isMobile()? 'col-11':'col-11'">
                    <h4>온라인 쇼핑몰</h4>
                </div>
                <div v-for="(item,index) in portfolio_nail" :key="index"  :class="isMobile()? 'col-11':'col-4'">
                    <h4>{{item.customer}}</h4>
                    <span>{{item.subject}}</span>
                    <div class="item-img-wrap">
                        <img :src="item.png" class="img-responsive"  alt="working">
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Recruit -->
        <div class="divide60" id="Recurit"></div>
        <div class="col-sm-12 col-md-10 col-lg-8">
            <div class="center-heading">
                <h2>Recruit</h2>
                <span class="center-line"></span>
            </div>  
            <div class="row justify-content-center">
                <div class="blog-post p-4">
                    <div class="blog-postbox">
                        <div class="text-center">
                            <img src="../assets/recruit.jpg" class="center-block img-responsive" alt="workimg" :style="isMobile()? 'width:100%':'width:80%;'">
                        </div>
                        <div class="results-box" style="text-align: left;">
                            <h4><font color="ef402a">모집분야</font></h4>
                            <p>-&nbsp;&nbsp; 프런트엔드 개발자 (javascript, VueJS, ReactJS)<br>
                            -&nbsp;&nbsp; 백엔드 개발자 (javascript, NodeJS, JAVA)<br>
                            -&nbsp;&nbsp; 앱 개발자 (iOS, Android) - Objective-C, Swift, Android Java, Kotlin
                            </p>

                            <h4><font color="ef402a">지원형태</font></h4>
                            <p>-&nbsp;&nbsp; 전문대 졸 이상(관련 학과/자격증 우대)<br>
                            </p>
                            <h4><font color="ef402a">모집기간</font></h4>
                            <p>-&nbsp;&nbsp; 상시채용<br>

                            </p>
                            <h4><font color="ef402a">지원방법</font></h4>
                            <p>-&nbsp;&nbsp; 지원을 희망하시는 분은 이력사항을 작성하여 아래 이메일로 전달해 주시면 서류전형 합격여부를 가급적 빠른 시간 내로 통보해 드립니다.
                            </p>
                        </div>
                        <div class="divide10"></div>
                        <div class="about-recruit">
                            <img src="@/assets/mailtojob.png" class="mb-1 popular-recruit2" alt="">
                            이메일 보내는 곳 : <a href="mailto:harry@rudacrew.com">harry@rudacrew.com</a>
                        </div>
                    </div> 
                </div>  
            </div>
        </div>
        <!-- location -->
        <div class="divide20"></div>
        <div class="col-sm-8">
            <div class="center-heading" id="Reference">
                <h2>Location</h2>
                <span class="center-line"></span>
            </div>  
        </div>
        <div class="row">
            <div clsss="location">
                <iframe class="contactUs" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7313.274308279972!2d126.88865386021612!3d37.50901906811053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9feb1acacf8b%3A0x9b80ee42f9fe2131!2zKOyjvCnro6jri6Ttgazro6g!5e0!3m2!1sko!2skr!4v1675848265408!5m2!1sko!2skr" allowfullscreen></iframe>
            </div>
            <div class="divide20"  id="ContactUs"></div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            history:[{
                year:'2021',
                subItem:[
                    {month:'11',content:'파이언넷과 신세계인터네셔날 S.I.VILLAGE 앱 개발 계약'},
                    {month:'07',content:'데브올과 Vinyl runable (달리기 앱) 앱 계약'},
                    {month:'01',content:'필라넷과 삼성전자복합인증 앱 운영 계약'},
                    {month:'01',content:'갤러리아몰 앱 운영 계약 2년차'},
                    {month:'01',content:'롯데홈쇼핑 앱 운영 게약'},
                    {month:'01',content:'롯데인터넷면세졈 운영 계약 3년차'},
                    ]
                },
                {
                year: '2020',
                subItem:[
                    {month:'10', content:'SecuWorks와 IOT 모니터링 관제 시스템 개발 계약'},
                    {month:'08', content:'신세계면세점 앱 개발 계약'},
                    {month:'01', content:'갤러리아몰 앱 운영 계약'},
                    {month:'01', content:'롯데인터넷면세점 운영 계약 2년차'},
                    ]
                },
                {
                year: '2019',
                subItem:[
                    {month:'12', content:'네오랩컨버전스와 네오스튜디오 iOS용 앱 개발 계약'},
                    {month:'12', content:'필라넷과 mOTP앱 개발 계약'},
                    {month:'08', content:'롯데인터넷면세점 트립톡 2차 개발 계약'},
                    {month:'08', content:'갤러리아몰 리뉴얼 앱 개발 프로젝트 계약'},
                    {month:'07', content:'롯데쇼핑 검색/탐색 개선 프로젝트 계약'},
                    {month:'07', content:'롯데인터넷면세점 상품상세 개선 프로젝트 계약'},
                    {month:'05', content:'롯데인터넷면세점 트립톡 1차 개발 계약'},
                    {month:'01', content:'롯데쇼핑 앱 운영 계약'},
                    {month:'01', content:'롯데인터넷면세점 앱 운영 계약'},
                    {month:'01', content:'갤러리아몰 앱 운영 계약'},
                    ]
                },
                {
                year: '2018',
                subItem:[
                    {month:'10', content:'롯데쇼핑 검색/탐색 개선 프로젝트 계약'},
                    {month:'08', content:'롯데쇼핑 투게더SDK 개발 및 적용 계약'},
                    {month:'07', content:'S.I.VILLAGE, JAJU 앱 리뉴얼 계약'},
                    {month:'06', content:'롯데인터넷면세점 앱 리뉼얼 계약'},
                    {month:'05', content:'롯데쇼핑 검색 고도화 프로젝트 계약'},
                    {month:'04', content:'LG Puricare 미니 공청기 연동 앱 개발 계약'},
                    {month:'03', content:'롯데쇼핑 보이스커머스 기능 개선 계약'},
                    {month:'01', content:'갤러리아몰 앱 운영 계약'},
                    {month:'01', content:'롯데쇼핑 앱 운영 계약'},
                    ]
                },
                {
                year: '2017',
                subItem:[
                    {month:'11', content:'AK몰 앱 개선 계약'},
                    {month:'10', content:'롯데닷컴 폴더 앱 개발 계약'},
                    {month:'04', content:'롯데닷컴 앱 리뉴얼 계약'},
                    ]
                },
                {
                year: '2022',
                subItem:[
                    {month:'10', content:'LG BizSkype 연동 앱 개발 계약'},
                    {month:'03', content:'FineVu 블랙박스 연동 앱 개발 계약'},
                    {month:'03', content:'11번가 글로벌 플랫폼 앱 개발 계약'}
                    ]
                }
            ],
            portfolio_iot:[
                {png:require('../assets/apps/iot_1.png'), customer:'', subject:''},
                {png:require('../assets/apps/iot_2.png'), customer:'', subject:''},
                {png:require('../assets/apps/iot_3.png'), customer:'', subject:''},
            ],
            portfolio_nail:[
                {png:require('../assets/apps/nail_1.jpg'), customer:'', subject:''},
                {png:require('../assets/apps/nail_2.jpg'), customer:'', subject:''},
                {png:require('../assets/apps/nail_3.jpg'), customer:'', subject:''},
                {png:require('../assets/apps/nail_4.jpg'), customer:'', subject:''},
                {png:require('../assets/apps/nail_5.jpg'), customer:'', subject:''},
                {png:require('../assets/apps/nail_6.jpg'), customer:'', subject:''},
            ],
            card:[
                {png:require('../assets/index_iot.png'),title:'IoT 관제시스템',content:['IoT 기기 정보를 중앙서버에서 관리하고 모바일 앱을 통해서도 쉽게 관제 할 수 있도록 하는 시스템 기획/설계/개발 및 클라우드를 활용한 시스템 구축']},
                {png:require('../assets/index_mall.png'),title:'온라인 쇼핑몰',content:['몰인몰을 포함한 다양한 형태의 쇼핑몰 기획/설계/개발 및 클라우드를 활용한 안정적인 서비스 운영을 제공.']},
                {png:require('../assets/index_webservice.png'),title:'웹 서비스 기획/개발',content:['고객의 서비스 핵심 가치를 고려한 웹 서비스를 기획 하고, 그에 가장 적합한 시스템을 설계/구축하여 안정적인 운영을 할 수 있도록 제공해 드립니다.']},
                {png:require('../assets/index_apps.png'),title:'모바일 앱 개발',content:['다양한 쇼핑몰, 보안기능 서비스, 기기 연동 서비스와 같은 다수의 개발 및 운영 경험을 기반으로 모바일 앱(네이티브, 하이브리드)을 개발 합니다.'],},
            ]
        }
    },
    methods:{
        isMobile() {
            var UserAgent = navigator.userAgent;

            if (UserAgent.match(/iPhone|iPod|iPad|Android|iOS|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i) != null || UserAgent.match(/LG|SAMSUNG|Samsung/) != null)
            {
                return true;
            }else{
                return false;
            }
        },
        txtReplace(text) {
            var repaceText = text.replace('\n','');
            return repaceText;
        }
    },
    mounted(){
    }
}
</script>

<style>
    html,body{
        overflow-x: hidden;
        margin:0;
    }

    .center-heading {
        text-align: center;
        margin-bottom: 20px;
    }

    .center-heading h2 {
        margin-bottom: 0;
        font-weight: 700;

        letter-spacing: -1px;
        color: #000;
        font-size: 30px;
    }
    .center-line {
        display: inline-block;
        width: 70px;
        height: 1px;
        border-top: 1px solid #bbb;
        /* border-bottom: 1px solid $skincolor; */
        margin: auto;
    }
    /* PC 스타일 */
    @media (min-width: 768px) {
        .img_1 {
            max-width: 640px;
            width: 100%;
            height: auto;
        }
    }
    /* 모바일 스타일 */
    @media (max-width: 767px) {
        .img_1 {
            width: 100%;
            height: auto;
        }
    }
</style>