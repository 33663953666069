<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <!--navbar-default-->
  <div v-if="!isMobile()" class="navbar navbar-default navbar-static-top yamm sticky" role="navigation">
      <div class="container">
          <div class="navbar-header" >
              <a class="navbar-brand" href="/#">RUDACREW</a>
          </div>
          <div>
              <ul class="nav navbar-nav navbar-nav-PC">
                  <li><a href="#Company">COMPANY</a></li>
                  <!-- <li><a href="#History">HISTORY</a></li> -->
                  <li><a href="#RecentWorks">Reference</a></li> 
                  <li><a href="#Recurit">Recurit</a></li>
                  <li><a href="#ContactUs">ContactUs</a></li>
              </ul>
          </div>
      </div>
  </div>
  <div v-else class="navbar navbar-default navbar-static-top yamm sticky" role="navigation">
      <div class="container justify-content-between">
          <div class="navbar-header" >
              <a class="navbar-brand" href="/#">RUDACREW</a>
          </div>
          <div>
              <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse" @click="openMenu">
              <span class="sr-only"></span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
              </button>
          </div>
      </div>
      <!--menu-->
      <div v-if="openedMenu">
          <ul class="nav navbar-nav">
              <li><a href="#Company" @click="openMenu">COMPANY</a></li>
              <!-- <li><a href="#History" @click="openMenu">HISTORY</a></li> -->
              <li><a href="#RecentWorks" @click="openMenu">Reference</a></li>
              <li><a href="#Recurit" @click="openMenu">Recurit</a></li>
              <li><a href="#ContactUs" @click="openMenu">ContactUs</a></li>
          </ul>
      </div>
  </div>
</template>

<script>
export default {
  data(){
      return{
        openedMenu:false
      }
  },
  methods:{
      isMobile() {
          var UserAgent = navigator.userAgent;

          if (UserAgent.match(/iPhone|iPod|iPad|Android|iOS|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i) != null || UserAgent.match(/LG|SAMSUNG|Samsung/) != null)
          {
              return true;
          }else{
              return false;
          }
      },
      openMenu(){
          return this.openedMenu = !this.openedMenu;
      }
  }
}
</script>