<template>
  <div v-if="showLayout">
    <Main/>
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import Main from './pages/main/main.vue'

export default {
  name: 'App',
  components: {
    Main,
  },
  computed: {
    showLayout() {
      // /diary-policy 경로일 때는 Header와 Footer를 숨김
      return this.$route.path !== '/diary-privacypolicy' && this.$route.path !== '/diary-deletepolicy';
    }
  }
}
</script>

<style>
</style>
