<template>
  <div class="main">
    <Header style="position:fixed; z-index:9999;"/>
    <Main/>
    <Footer/>
  </div>
</template>

<script>
import Main from '../../components/index.vue'
import Footer from '../../components/footer.vue'
import Header from '../../components/header.vue'

export default {
  name: 'App',
  components: {
    Header,
    Main,
    Footer
  }
}
</script>

<style>
.main {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
