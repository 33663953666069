<template>
  <div class="privacy-container">
    <iframe
      src="/privacypolicy.html"
      frameborder="0"
      class="privacy-iframe"
      width="100vw"
      height="100vh"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style scoped>
.privacy-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px 20px 20px;
}

.privacy-iframe {
  border: none;
  width: 100vw;
  height: 100vh;
}
</style>
