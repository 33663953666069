import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import router from './router'  // 라우터 파일을 임포트

Vue.use(BootstrapVue)
Vue.config.productionTip = false

import '@/design/index.css'
new Vue({
  router,  // 라우터를 등록
  render: h => h(App),
}).$mount('#app')
